<template lang="pug">
  div
    v-row
      v-col(cols="3")
        create-account-code
      v-col(cols="9")
        table-account-code
</template>
<script>
export default {
  name: 'AccountCode',
  components: {
    createAccountCode: () => import('./Create'),
    tableAccountCode: () => import('./Table'),
  },
}
</script>